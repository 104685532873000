export function initBrandingObserver($brandingLeft, $brandingRight) {
  return new IntersectionObserver(changes => {
    if (changes[0].isIntersecting) {
      $brandingLeft.classList.remove('branding--fixed');
      $brandingRight.classList.remove('branding--fixed');
    } else {
      $brandingLeft.classList.add('branding--fixed');
      $brandingRight.classList.add('branding--fixed');
    }
  });
}

const setBgImageUrlAndColor = defaultColor => ($element, url) => {
  $element.style.backgroundColor = defaultColor;
  $element.style.backgroundImage = `url(${url})`;
};

const setLink = (link, $element, target) => {
  $element.href = link;
  $element.target = target || '_blank';
};

const defaultBrandingElementsClasses = {
  container: '.js-branding',
  left: '.js-branding-left',
  right: '.js-branding-right',
  top: '.js-branding-top',
  header: '.js-header',
  placeholder: '.js-page__top-placeholder'
};

export function enableBrandingBehavior(
  config,
  brandingElementsClasses = defaultBrandingElementsClasses
) {
  const $container = document.querySelector(brandingElementsClasses.container);
  const $brandingLeft = document.querySelector(brandingElementsClasses.left);
  const $brandingRight = document.querySelector(brandingElementsClasses.right);
  const $brandingTop = document.querySelector(brandingElementsClasses.top);
  const $header = document.querySelector(brandingElementsClasses.header);
  const $placeholder = document.querySelector(brandingElementsClasses.placeholder);

  if (!$container || !$brandingLeft || !$brandingRight || !$brandingTop || !$header || !config || !$placeholder) {
    return null;
  }

  const {defaultBgColor, link, imageLeft, imageRight, imageTop, target} = config;
  const setBgImageUrl = setBgImageUrlAndColor(defaultBgColor);

  setBgImageUrl($brandingLeft, imageLeft);
  setBgImageUrl($brandingRight, imageRight);
  setBgImageUrl($brandingTop, imageTop);

  setLink(link, $brandingLeft, target);
  setLink(link, $brandingRight, target);
  setLink(link, $brandingTop, target);

  $container.classList.remove('branding--hidden');
  $placeholder.style.display = 'none';

  const observer = initBrandingObserver($brandingLeft, $brandingRight);

  observer.observe($brandingTop);
  return observer;
}

export function enableMobileBrandingBehavior(
  config,
  brandingElementsClasses = defaultBrandingElementsClasses
) {
  const $brandingTop = document.querySelector(brandingElementsClasses.top);
  const $header = document.querySelector(brandingElementsClasses.header);
  const $placeholder = document.querySelector(brandingElementsClasses.placeholder);

  if (!$brandingTop || !$header || !config || !$placeholder) {
    return null;
  }

  const {defaultBgColor, link, imageTop, target} = config;

  setBgImageUrlAndColor(defaultBgColor)($brandingTop, imageTop);

  setLink(link, $brandingTop, target);

  $placeholder.style.display = 'none';
}

export function disableBrandingBehavior(observer) {
  observer?.disconnect();
}
