import {MILLISECOND_IN_SECOND} from './constants/common';
import {startInterval} from './helpers/interval.js';
import {enableBrandingBehavior, enableMobileBrandingBehavior} from './modules/enable-branding-behavior.js';
import {ANALYTIC_EVENTS} from './services/analytics/pub-sub/constants';
import {publishEvent} from './services/analytics/pub-sub/index';
import {debounce, isInViewport} from './services/helpers';

const SCREEN_OFFSET = 100;
const DEBOUNCE_TIME = 250;
const {slots = [], targeting, targetingContentTypes} = window.Site;

function getSlotSize({size = ''} = {}) {
  if (!size || !size.length) {
    throw new TypeError('There is no size property');
  }

  try {
    return JSON.parse(`[${size}]`);
  } catch (e) {
    throw new TypeError('Error parsing slot size');
  }
}

const getCurrentTargeting = $target => {
  const {id} = $target?.closest?.('.js-main-wrapper')?.querySelector('.js-article')?.dataset || {};

  return targetingContentTypes?.[id] ? targetingContentTypes[id] : targeting;
};

const getCurrentSlotAdditionalparams = $target => {
  if (!$target.classList.contains('advert-betting-sport')) {
    return {};
  }

  const article = $target.closest('.js-main-wrapper')?.querySelector('.js-article');

  if (!article) {
    return {};
  }

  const additionalParams = {};

  additionalParams.puid1 = article.dataset.puid1 ?? '';
  additionalParams.puid2 = article.dataset.puid2 ?? '';
  additionalParams.puid3 = article.dataset.puid3 ?? '';
  additionalParams.puid4 = article.dataset.puid4 ?? '';
  additionalParams.puid5 = article.dataset.puid5 ?? '';
  additionalParams.puid5 = article.dataset.puid5 ?? '';

  return additionalParams;
};

const loadMoreInfiniteAds = (currentSlot, $target) => {
  const currentTargeting = getCurrentTargeting($target);
  const definedSlot = window.googletag
    .defineSlot(currentSlot.slot, getSlotSize(currentSlot))
    .addService(window.googletag.pubads());

  Object.keys(currentTargeting).forEach(key => {
    const value = currentTargeting[key];

    definedSlot.setTargeting(key, value instanceof Array ? value : value.toString());
  });

  const $adContainer = document.createElement('div');

  $adContainer.id = definedSlot.getSlotElementId();
  $target.appendChild($adContainer);

  window.googletag.display(definedSlot);
};

const loadDeferredAds = $target => {
  window.googletag.cmd.push(() => {
    const {code, lazyloadInfiniteAd} = $target?.dataset || {};
    const currentSlot = window.Site.slots.find(slot => slot.code === code);

    if (currentSlot) {
      if (lazyloadInfiniteAd) {
        loadMoreInfiniteAds(currentSlot, $target);
      } else {
        window.googletag
          .defineSlot(currentSlot.slot, getSlotSize(currentSlot), currentSlot.code)
          .addService(window.googletag.pubads());

        window.googletag.display(code);
      }
    }
  });
};

const renderedSlots = {};

const addAdFoxAnalytics = (containerId, callbacks) => {
  const slotContainerElement = document.querySelector(`#${containerId}`);

  slotContainerElement.addEventListener('click', () => {
    publishEvent(ANALYTIC_EVENTS.ADFOX_TAP_ON_BANNER, {
      containerId
    });
  });

  const slotObserver = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        publishEvent(ANALYTIC_EVENTS.ADFOX_PLACEMENT_IMPRESSION, {
          containerId
        });

        if (renderedSlots[containerId]) {
          publishEvent(ANALYTIC_EVENTS.ADFOX_CREATIVE_IMPRESSION, {
            containerId
          });
        }
      }
    });
  }, {threshold: 1});

  slotObserver.observe(slotContainerElement);

  return {
    onRender: () => {
      renderedSlots[containerId] = true;

      if (callbacks) {
        callbacks.onRender && callbacks.onRender();
      }
    },
    onError: error => {
      publishEvent(ANALYTIC_EVENTS.ADFOX_ERROR, {
        containerId,
        message: error
      });
    },
    onStub: () => {
      publishEvent(ANALYTIC_EVENTS.ADFOX_STUB, {
        containerId
      });
    }
  };
};

const loadDeferredAdfoxAds = $target => {
  const {id: slotId} = $target?.dataset || {};
  const {adfoxUnits = []} = window.Site;
  const slot = adfoxUnits.find(({containerId}) => containerId === slotId);
  const puid7 = $target.dataset.puid7 || '';

  if (slot) {
    const newContainerId = `${$target.id}`;
    const currentTargeting = getCurrentTargeting($target);
    const additionalParams = getCurrentSlotAdditionalparams($target);

    window.yaContextCb.push(() => {
      window.Ya.adfoxCode.create({
        ...slot,
        containerId: newContainerId,
        params: {
          ...slot.params,
          ...window.Site.adFoxParams,
          ...additionalParams,
          puid7,
          pk: [
            (currentTargeting.category || []).join(' '),
            (currentTargeting.tags || []).join(' '),
            currentTargeting.attr_sponsored_by || '',
            currentTargeting.attr_content_type || ''
          ].filter(Boolean).join(' '),
          pke: '1'
        },
        ...addAdFoxAnalytics(newContainerId)
      });
    });

    if (slot.reloadInterval) {
      startInterval(() => window.Ya?.adfoxCode?.reload(newContainerId, {onlyIfWasVisible: false}),
        Number(slot.reloadInterval) * MILLISECOND_IN_SECOND);
    }
  }
};

const lazyLoadAds = () => {
  document.addEventListener('lazybeforeunveil', e => {
    if (e.target.getAttribute('data-lazyload-ad')) {
      loadDeferredAds(e.target);
    }

    if (e.target.getAttribute('data-lazyload-adfox-ad')) {
      loadDeferredAdfoxAds(e.target);
    }
  });
};

const loadAdsByScroll = scrollTop => {
  const $scrollLoadingAd = document.querySelector('[data-scrollload-ad]');

  if (scrollTop >= SCREEN_OFFSET && $scrollLoadingAd) {
    loadDeferredAds($scrollLoadingAd);
    document.removeEventListener('scroll', adsScrollHandler); /* eslint-disable-line */
  }
};

const getSlotById = id => window.googletag.pubads().getSlots().filter(slot => slot.getSlotElementId() === id)[0];

/* Ads refresh */

export const refreshDFPAdvertisement = slotId => {
  window.googletag.cmd.push(() => {
    window.googletag.display(slotId);
    window.googletag.pubads().refresh([getSlotById(slotId)]);
  });
};

const onSlotRenderEnded = slotName => {
  if ((/Desktop_Anchor|Mobile_Anchor|js-adfox-anchor/).test(slotName)) {
    const $anchorCloseButton = document.querySelector('.js-advert-close');

    if (!$anchorCloseButton) {
      return;
    }

    $anchorCloseButton.classList.remove('advert-anchor-close--hidden');

    $anchorCloseButton.addEventListener('click', event => {
      event.stopPropagation();
      event.target.parentNode.classList.toggle('advert-anchor--collapsed');
    });
  }
};

const refreshTimeout = 30000; // update refresh timeout here if you need
const refreshSlotTimeouts = {};

const refreshSlot = slot => {
  window.googletag.cmd.push(() => {
    window.googletag.pubads().refresh([slot]);
  });
};

const refreshSlotAfterTimeout = ({slot}, timeout = 30000) => {
  const slotName = slot.getAdUnitPath();

  refreshSlotTimeouts[slotName] = window.setTimeout(() => {
    refreshSlot(slot);
  }, timeout);
};

function refreshSlotIfViewable(timeout) {
  window.googletag.pubads().addEventListener(
    'impressionViewable',
    e => refreshSlotAfterTimeout(e, timeout)
  );
}

function trackSlotVisibilityForRefresh(timeout) {
  window.googletag.pubads().addEventListener(
    'slotVisibilityChanged',
    ({slot, inViewPercentage}) => {
      const slotName = slot.getAdUnitPath();

      if (inViewPercentage === 0) {
        clearTimeout(refreshSlotTimeouts[slotName]);
        refreshSlotTimeouts[slotName] = 0;
      }

      if (inViewPercentage > 50 && refreshSlotTimeouts[slotName] === 0) {
        refreshSlotAfterTimeout({slot}, timeout);
      }
    }
  );
}

/*
 * GoogleAdManager
 */

window.googletag = window.googletag || {cmd: []};

const slotsWithoutLazyLoading = slots.filter(slot => {
  const $slot = document.getElementById(slot.code);

  if (!$slot) {
    return false;
  }

  return !$slot.parentElement?.className?.includes('lazyload');
});

if (window.googletag.cmd) {
  window.googletag.cmd.push(() => {
    slotsWithoutLazyLoading.forEach(slot => {
      window.googletag
        .defineSlot(slot.slot, getSlotSize(slot), slot.code)
        .addService(window.googletag.pubads());
    });

    Object.keys(targeting).forEach(key => {
      const value = targeting[key];

      window.googletag.pubads().setTargeting(key, value instanceof Array ? value : value.toString());
    });

    window.googletag.pubads().addEventListener('slotRenderEnded', event => {
      !event.isEmpty && onSlotRenderEnded(event.slot.getAdUnitPath());
    });

    if (refreshTimeout) {
      refreshSlotIfViewable(refreshTimeout);
      trackSlotVisibilityForRefresh(refreshTimeout);
    }

    window.googletag.pubads().set('page_url', window.location.href);
    window.googletag.pubads().setCentering(true);
    window.googletag.pubads().enableSingleRequest();
    window.googletag.pubads().collapseEmptyDivs();
    window.googletag.enableServices();
  });

  window.googletag.cmd.push(() => {
    slotsWithoutLazyLoading.forEach(slot => window.googletag.display(slot.code));
  });
}

lazyLoadAds();

const adsScrollHandler = debounce(() => loadAdsByScroll(window.scrollY), DEBOUNCE_TIME);

document.addEventListener('scroll', adsScrollHandler);
/*
 * AdFox
 */

window.yaContextCb = window.yaContextCb || [];
const {adfoxUnits = []} = window.Site;

adfoxUnits
  .filter(slot => !!window[slot.containerId])
  .forEach(slot => {
    const newSlot = {
      ...slot,
      params: {
        ...slot.params,
        ...window.Site.adFoxParams,
        pk: [
          (targeting.category || []).join(' '),
          (targeting.tags || []).join(' '),
          targeting.attr_sponsored_by || '',
          targeting.attr_content_type || ''
        ].filter(Boolean).join(' '),
        pke: '1'
      },
      ...addAdFoxAnalytics(slot.containerId, {
        onRender: () => {
          onSlotRenderEnded(slot.containerId);
        }
      })
    };

    /*
     * slot is not rendering branding if it is hidden
     */
    if (document.getElementById(slot.containerId).classList.contains('advert--adfox-hidden')) {
      document.getElementById(slot.containerId).classList.remove('advert--adfox-hidden');
    }

    if (document.getElementById(slot.containerId).classList.contains('advert--hidden')) {
      document.getElementById(slot.containerId).classList.remove('advert--hidden');
    }

    window.yaContextCb.push(() => window.Ya?.adfoxCode.create(newSlot));

    if (newSlot.reloadInterval && newSlot.containerId) {
      startInterval(() => {
        window.Ya?.adfoxCode?.reload(newSlot.containerId, {onlyIfWasVisible: false});
        if (isInViewport(document.querySelector(`#${newSlot.containerId}`))) {
          publishEvent(ANALYTIC_EVENTS.ADFOX_CREATIVE_IMPRESSION, {
            containerId: newSlot.containerId
          });
        }
      }, Number(newSlot.reloadInterval) * MILLISECOND_IN_SECOND);
    }
  });

/*
 * For Branding (Adfox and Google)
 */
window.enableBrandingBehavior = enableBrandingBehavior;
window.enableMobileBrandingBehavior = enableMobileBrandingBehavior;

